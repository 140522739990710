/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/material.scss';
@import 'assets/scss/hover.css';

body {
    margin: 1rem !important;
    background: radial-gradient(circle, mat-color($primary, 400) 0%, mat-color($primary, 100) 100%);
    color: $dark-primary-text;
}

h1 {
    margin: 0 !important;
}

h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    i {
        color: mat-color($accent, 500);
        font-size: 2rem;
    }

    &:has(+ .form) {
        transition: 0.3s all ease-out;

        i {
            transition: 0.3s all ease-out;
        }
    }

    &:has(+ .form:focus-within) {
        transform: translateX(10px);

        i {
            transform: rotate(90deg);
        }
    }
}

button {
    float: right;
    font-weight: 400 !important;
    letter-spacing: 0.05rem;

    i {
        font-size: 1.2rem;
    }
}