// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import '~@angular/material/theming';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$dark-primary-text: #212529;
$light-primary-text: #fff;

$primary-palette: (
  10: hsl(215, 46%, 100%),
  50: hsl(215, 46%, 92%),
  100: hsl(215, 46%, 78%),
  200: hsl(215, 46%, 68%),
  300: hsl(215, 46%, 55%),
  400: hsl(215, 46%, 40%),
  500: hsl(215, 46%, 25%),
  600: hsl(215, 46%, 20%),
  700: hsl(215, 46%, 15%),
  800: hsl(215, 46%, 10%),
  900: hsl(215, 46%, 5%),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$accent-palette: (
  10: hsl(97, 59%, 100%),
  50: hsl(97, 59%, 90%),
  100: hsl(97, 59%, 82%),
  200: hsl(97, 59%, 75%),
  300: hsl(97, 59%, 66%),
  400: hsl(97, 59%, 58%),
  500: hsl(97, 59%, 49%),
  600: hsl(97, 59%, 35%),
  700: hsl(97, 59%, 25%),
  800: hsl(97, 59%, 12%),
  900: hsl(97, 59%, 5%),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$primary-color: mat.define-palette($primary-palette);
$accent-color: mat.define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $primary-color,
    accent: $accent-color,
    warn: $warn,
  )
));

$alt-theme: mat.define-light-theme((
  color: (
    primary: $accent-color,
    accent: $primary-color,
    warn: $warn,
  )
));

$color: mat.get-color-config($theme);
$primary: map-get($color, primary);
$accent: map-get($color, accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);